<template>
    <div class="symbol__ignition-status">
        <span class="symbol__bg-icon icon" v-show="ignition">
            <span class="icon__symbol"><SvgIcon class="icon" name="units__ignition_bg"/></span>
        </span>
        <span class="symbol__ignition-icon icon" v-show="ignition">
            <span class="icon__symbol"><SvgIcon class="icon" name="units__ignition_symbol"/></span>
        </span>
    </div>
</template>
<script>
    export default {
        name: 'UnitsList_unit_ignition',
        props: [
            'ignition',
        ],
        data() {
            return {}
        },
        computed: {
        },
        updated: function() {
           // console.log(`UnitsList_unit_ignition ${this.ignition} updated`);
        },
    }
</script>