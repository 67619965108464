<template>
    <ButtonIcon_dual_status
        :button-class="'mtb__-4'"
        :disabled="true"
        :icon="isOnMap ? 'common__show' : 'common__show_disabled'"
        :icons="['common__show', 'common__show_disabled']"
        :value="isOnMap"
        @click="setOnMap"
    />
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_menu_onmap',
        props: [
            'unitId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsOnMap",
            ]),
            isOnMap(){
                return this.unitsOnMap.includes(this.unitId)
            },
        },
        methods: {
            ...mapActions([
                "setUnitShowOnMap",
            ]),
            setOnMap(){
                this.setUnitShowOnMap({
                    unitId: this.unitId,
                    showOnMap: !this.isOnMap
                });
                return false;
            },
        },
        updated: function() {
            // console.log(`unit menu onmap ${this.unitId} updated`, this.isOnMap);
        },
    }
</script>
