<template>
    <div class="list__status">
        <!-- display: none -->
        <span class="icon" v-show="isTracking"> <span class="icon__symbol"><SvgIcon class="icon" name="common__status_tracking" /></span> </span>
        <span class="icon" v-show="!isTracking && !isOnMap"> <span class="icon__symbol"><SvgIcon class="icon" name="common__status_not_show" /></span> </span>
        <!-- common__status_not_show common__status_tracking -->
    </div>
</template>

<script>
    import {
        // mapActions,
        mapGetters,
    } from "vuex";

    export default {
        name: "UnitsList_unit__status",
        props: [
            'unitId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsOnMap",
                "unitTracking",
            ]),
            isOnMap(){
                return this.unitsOnMap.includes(this.unitId)
            },
            isTracking(){
                return this.unitTracking === this.unitId
            },

        },
        methods: {
            // ...mapActions([
            // ]),
        },
        updated: function() {
            // console.log(`unit status ${this.unitId} updated`);
        },
    }
</script>

<style scoped>

</style>