<template>
    <div class="symbol__text-status">{{status}}</div>
</template>
<script>
    export default {
        name: 'UnitsList_unit_status',
        props: [
            'status',
        ],
        data() {
            return {}
        },
        computed: {
        },
        updated: function() {
           // console.log(`UnitsList_unit_status ${this.status} updated`);
        },
    }
</script>