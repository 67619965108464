<template>
    <li class="list__item item_view_unit"
        :class="{'item_active': isActive, 'item_inactive': !isLicenseActive}"
        v-show="isValid && showUnit && isFilteredUnit"
        v-if="showUnit && (accessRight >= getAccessRights.read)"
        @click="setUnit"
        @mouseover="showMenu = true" @mouseleave="showMenu = false"
    >
        <div class="list__symbol symbol">
            <div class="symbol__image-box" :style="{color: unitIconColor}">
                <SvgIcon style="height: 100%; width: 100%" :name="unitIcon" class="icon svg-icon-unit-marker" />
            </div>
            <UnitsList_unit_ignition v-if="isLicenseActive" :ignition="ignition" />
            <UnitsList_unit_moving_status v-if="isLicenseActive" :is-moving="isMoving" />
            <UnitsList_unit_status_icon icon-name="video__camera" v-if="isLicenseActive && hasVideo" :status="videoStatus" />
            <UnitsList_unit_status_icon icon-name="unit__reefer"
                                  :icon-width="10"
                                  :icon-height="10"
                                  v-if="isReefer"
                                  />
            <UnitsList_unit_status :status="status" />
        </div>
        <div class="list__title-line">
            <h4 class="list__title">{{unitName}}</h4>
            <UnitsList_unit_timeago :unit-id="unitId" />
        </div>
        <div class="list__address-line">
            <UnitsList_unit_address :address="address" />
            <UnitsList_unit__status :unit-id="unitId" />
        </div>
        <UnitsList_unit_menu v-if="showMenu && isLicenseActive" :unit-id="unitId" />
    </li>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import { getAccessRights } from "@/lib/access-rights";

    import UnitsList_unit_ignition from "./UnitsList_unit_ignition.vue";
    import UnitsList_unit_moving_status from "./UnitsList_unit_moving_status.vue";
    import UnitsList_unit_status from "./UnitsList_unit_status.vue";
    import UnitsList_unit_timeago from "./UnitsList_unit_timeago.vue";
    import UnitsList_unit_address from "./UnitsList_unit_address.vue";
    import UnitsList_unit__status from "./UnitsList_unit__status.vue";
    import UnitsList_unit_menu from "./UnitsList_unit_menu.vue";
    import UnitsList_unit_menu_lite from "./UnitsList_unit_menu.lite.vue";
    import UnitsList_unit_status_icon from "./UnitsList_unit_status_icon.vue";

    export default {
        name: 'UnitsList_unit',
        components: {
            UnitsList_unit_status_icon,
            UnitsList_unit_address,
            UnitsList_unit_timeago,
            UnitsList_unit_status,
            UnitsList_unit_moving_status,
            UnitsList_unit_ignition,
            UnitsList_unit__status,
            'UnitsList_unit_menu': (process.env.VUE_APP_PACKAGE !== "lite")?
                UnitsList_unit_menu : UnitsList_unit_menu_lite,
        },
        props: [
            'unitId',
            'groupId',
            // 'unit',
            // 'unitLmsg',
            'showUnit',
            // 'filteredUnit'
        ],
        data() {
            return {
                getAccessRights,
                showMenu: false,
            }
        },
        watch: {
            isActive: function (newVl/*, oldVl*/) {
                // console.log("isActive", newVl, oldVl)
                //ToDo reset filter
                if(newVl) setTimeout(() => this.scrollToUnit() , 300 )
            }
        },
        computed: {
            ...mapGetters([
                "getAppUserSettings",
                "getUserStorageKey",
                "getAppUserUnits",

                "getTimeToday",
                "getUnitActive",
                "unitsByIds",
                "unitsLmsgsByIds",
                "unitsOnMap",
                // "groupsShowUnits",
                // "groupsShowUnitsByIds",
                "filteredUnits",
                "getFilterSearchLists",

                "showSectionInfo",
                "getSectionInfo",
                "showSectionWidget",
                "getSectionWidget",
            ]),
            isActive(){
                return this.getUnitActive === this.unitId
            },
            unit(){
                return this.unitsByIds[this.unitId]
            },
            isReefer(){
              // return this.unit.isReefer
                return this.unit.hw_type == 'globe_tracker'
            },
            hasVideo(){
              return !!this.unit.video
            },
            videoStatus(){
                return (this.unit.video || {}).status
            },
            accessRight(){
                return  this.unit.access_right
            },
            unitName(){
                return (this.unit && this.unit.name>'') ? this.unit.name : '-'
            },
            isLicenseActive(){
                return (this.unit.license && this.unit.license.expired && this.unit.license.expired.utc) >= this.getTimeToday
            },
            unitIcon(){
                // let type = this.getFilterSearchLists.Units.types.find(t => {return t.id == this.unit.type })
                // let icon = "common__"+(type ? type.icon : 'car')+"_lg"
                let icon = (!this.isLicenseActive) ? 'common__warning' :
                    (this.unit && this.unit.icon) ? this.unit.icon : 'map__car_type_sedan'
                return icon
            },
            unitIconColor(){
                return (this.unit && this.unit.icon_color) ? this.unit.icon_color : '#003B79'
            },
            // isShowUnit(){
            //     return !(!this.groupsShowUnitsByIds[this.groupId])
            // },
            isFilteredUnit(){
                return this.filteredUnits.includes(this.unitId)
            },
            isValid(){
                return this.unit && this.unit.name > ''
            },
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId] || {}
            },
            unitLmsgStatus(){
                return this.unitLmsg.status || {}
            },
            ignition(){
                return (this.unitLmsgStatus.ign !== null && this.unitLmsgStatus.ign !== undefined) ? this.unitLmsgStatus.ignition : null
            },
            isParked(){
                return this.unitLmsgStatus.isParked
            },
            isMoving(){
                return this.unitLmsgStatus.isMoving
            },
            maxSpeed(){
                return this.unit.max_speed > 0 ? this.unit.max_speed : ''
            },
            speed(){
                return (this.unitLmsg.speed > 0) ? Math.round(this.unitLmsg.speed) : ''
            },
            speedUnits(){
                return this.getAppUserUnits.speed
            },
            status() {
                return (!this.isLicenseActive) ? this.$t('text.Expired') :
                    (this.isParked) ? this.$t('text.Parked') :
                    (this.isMoving) ? this.speed + (this.maxSpeed > '' ? '/' + this.maxSpeed : '') + ' ' + this.speedUnits :
                        '';
            },
            address(){
                return (this.unitLmsg.address > ' ') ? this.unitLmsg.address :
                    (this.unitLmsg.latlng) ? "lat: "+this.unitLmsg.latlng.lat+", lng: "+this.unitLmsg.latlng.lng : ''
            },
        },
        methods: {
            ...mapMutations([
                "setAllUnitsShowOnMap",
            ]),
            ...mapActions([
                "setUnitShowOnMap",
                "setMapBoundsByUnits",
                "setUnitTracking",
                "setSectionInfo",
                "setSectionWidget",
                "setUnitActive"
            ]),
            scrollToUnit(){
                let elem = document.querySelector('.item_view_unit.item_active');
                elem.scrollIntoView({ block: "center", behavior: "smooth" ,inline:"center"})
            },
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                })
            },
            setUnit(){
                if (!this.isLicenseActive) {
                    console.warn('Licence expired', this.unitId)
                    return
                }
                localStorage.setItem(this.getUserStorageKey+'.unit.active', this.unitId)
                this.setUnitActive(this.unitId)
                this.setUnitTracking(this.unitId)
                if(this.showSectionInfo){
                    let sectionInfo = this.getSectionInfo
                    sectionInfo.props = {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                    this.setSectionInfo(sectionInfo)
                }
                if(this.showSectionWidget){
                    let sectionWidget = this.getSectionWidget
                    sectionWidget.props = {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                    this.setSectionWidget(sectionWidget)
                }
            },
            onEdit(){
                if(process.env.VUE_APP_PACKAGE === "lite") {
                    this.displaySectionInfo('UnitEdit')
                }else{
                    this.displaySectionWidget('UnitEdit')
                }
            }
        },
        updated: function() {
            // console.log(`unit ${this.unitId} updated`);
        },
        mounted() {
        },
        beforeDestroy() {
        },
    }
</script>
