<template>
    <div class="list__update update" :class="timeAgoClass"><!-- update_status_online update_status_online-recently update_status_not-online-recently update_status_not-online-long-time -->
        <span class="list__update-time">{{timeAgoInfo}}</span><!-- timeAgo | date('ago') -->
        <div class="list__update-indicator"></div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_timeago',
        props: [
            'unitId',
            // 'timeAgo',
        ],
        data() {
            return {
                // now: Date.now(),
                // timeAgo: false,
                inc: 0,
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                // "unitsByIds",
                "unitsLmsgsByIds",
            ]),
            // unit() {
            //     return this.unitsByIds[this.unitId]
            // },
            unitLmsg() {
                return this.unitsLmsgsByIds[this.unitId]
            },
            timeAgo(){
                if (!this.unitLmsg) return false;
                return this.unitLmsg.timeAgo
            },
            timeAgoInfo() {
                if (this.timeAgo === false){
                    return '-';
                }
                let timeAgo = this.timeAgo
                let sign = ''
                if(timeAgo<0){
                    sign = '-'
                    timeAgo *= -1
                }
                //return timeAgo
                let time = {
                    y: Math.floor(timeAgo / (365 * 24 * 60 * 60)),
                    d: Math.floor(timeAgo / (24 * 60 * 60)),
                    h: Math.floor(timeAgo / (60 * 60)),
                    m: Math.floor(timeAgo / (60)),
                    s: Math.round(timeAgo / (1)),
                }
                // if(time.y) time = time.y+'y'
                // else
                if(time.d > 9) time = time.d+'d'
                else if(time.h) time = time.h+'h'
                else if(time.m) time = time.m+'m'
                else time = time.s+'s'

                //+'('+new Date(this.unitLmsg.time).toISOString()+')'
                return time ? sign + this.$t('{time} ago', {time}) : ''
            },
            timeAgoClass() {
                if (this.timeAgo === false){
                    return '';
                }
                let timeAgo = this.timeAgo
                //if(timeAgo<0) timeAgo *= -1

                if(timeAgo <= 5*60){
                    return 'update_status_online'
                }else
                if(timeAgo <= 15*60){
                    return 'update_status_online-recently'
                }else
                if(timeAgo <= 60*60){
                    return 'update_status_not-online-recently'
                }else
                {
                    return 'update_status_not-online-long-time'
                }
            },
        },
        beforeUpdate() {
            // console.log(this.inc)
            // this.inc = 0
        },
        updated: function() {
            // console.log(`UnitsList_unit_timeago ${this.unitId} updated`);
        },
        mounted() {
            // console.log(this.$root)
        },
    }
</script>