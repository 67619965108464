<template>
    <h5 class="list__address">{{address}}</h5>
</template>
<script>
    export default {
        name: 'UnitsList_unit_address',
        props: [
            'address'
        ],
        data() {
            return {}
        },
        computed: {
        },
        updated: function() {
           // console.log(`UnitsList_unit_address ${this.address} updated`);
        },
    }
</script>