<template>
    <div class="list__button-group" >
        <button class="button button_view_icon button_theme_base mtb__-4" data-type="open-drop-menu">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__more" /></span> </span>
        </button>
        <div class="list__dropdown-menu dropdown-menu position_center-bottom" data-type="" >
            <div class="dropdown-menu__wrap">
                <ul class="dropdown-menu__list">
                    <li class="dropdown-menu__item item"
                        v-for="(item, i) in menus"
                        :key="'UnitsList_unit_dropdown-menu__item_'+i"
                        :class="{'item_type_separator': item.item_type == 'separator'}"
                        @click="bindToMe(item.onClick)()"
                    >
                        <span v-if="item.icon" class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="item.icon" /></span> </span>
                        <span v-if="item.name" class="dropdown-menu__item-name">{{item.name}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <UnitsList_unit_menu_tracking :unit-id="unitId" />
        <UnitsList_unit_menu_onmap :unit-id="unitId" />
    </div>
</template>

<script>
    import {
        mapActions,
        // mapGetters,
    } from "vuex";
    import UnitsList_unit_menu_onmap from "./UnitsList_unit_menu_onmap.vue";
    import UnitsList_unit_menu_tracking from "./UnitsList_unit_menu_tracking.vue";

    export default {
        name: 'UnitsList_unit_menu',
        props: [
            'unitId',
        ],
        components: {
            UnitsList_unit_menu_tracking,
            UnitsList_unit_menu_onmap,
        },
        data() {
            return {
                menus_full: [
                    {
                        "icon": "common__track_day",
                        "name": "Build track for last day",
                        "onClick": function () { this.buildUnitTrack24h() },
                    }, {
                        "icon": "common__track",
                        "name": "Build track",
                        "onClick": function () { this.buildUnitTrack() },
                    }, {
                        "item_type": "separator",
                        "onClick": function () { return false; },
                    }, {
                        "icon": "common__reports",
                        "name": "Execute report",
                        "onClick": function () { this.execReport() },
                    }, {
                        "icon": "common__command",
                        "name": "Command",
                        "onClick": function () { this.displaySectionWidget('UnitCommand') },
                    }, {
                        "icon": "common__video",
                        "name": "Live video ",
                        "onClick": function () { this.displaySectionInfo('UnitLiveVideo') },
                    }, {
                        "icon": "common__location",
                        "name": "Locator",
                        "onClick": function () { this.displaySectionWidget('UnitLocator') },
                    }, {
                        "icon": "common__chat",
                        "name": "Chat",
                        "onClick": function () { this.displaySectionInfo('UnitChat') },
                    }, {
                        "item_type": "separator",
                        "onClick": function () { return false; },
                    }, {
                        "icon": "common__edit_lg",
                        "name": "Settings",
                        "onClick": function () { this.displaySectionWidget('UnitEdit') },
                    }, {
                        "icon": "common__info",
                        "name": "Info",
                        "onClick": function () { this.displaySectionInfo('UnitInfo') },
                    },
                ],
                menus_lite: [
                    {
                        "icon": "common__edit_lg",
                        "name": "Edit",
                        "onClick": function () { this.displaySectionInfo('UnitEdit') },
                    }
                ]
            }
        },
        computed: {
            // ...mapGetters([
            // ]),
            menus(){
                return (process.env.VUE_APP_PACKAGE === "lite")? this.menus_lite : this.menus_full
            }
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
                "setSectionWidget",
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                })
            },
            buildUnitTrack(){
                alert('buildUnitTrack')
            },
            buildUnitTrack24h(){
                alert('buildUnitTrack24h')
            },
            execReport(){
                alert('execReport')
            },
            bindToMe(handler) {
                return handler.bind(this);
            },
            //https://stackoverflow.com/questions/52811581/how-to-pass-a-dynamic-function-name-to-the-click-event-in-vue-js
            //[item.click](item.click_params)
            // @click="handle_function_call(item.click, item.click_params)"
            handle_function_call(function_name, function_params) {
                this[function_name](function_params)
            },
        },
        updated: function() {
            //console.log(`unit menu ${this.unitId} updated`);
        },
    }
</script>

<style scoped>

</style>