<template>
    <div class="list__button-group" >
        <button class="button button_view_icon button_theme_base mtb__-4" data-type="open-drop-menu"
                v-if="output.length"
        >
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__command" /></span> </span>
        </button>
        <div class="list__dropdown-menu dropdown-menu position_center-bottom">
            <div class="dropdown-menu__wrap">
                <ul class="dropdown-menu__list">
                    <template v-for="(out, i) in output">
                        <li class="dropdown-menu__item item item_not-has-icon"
                            :key="i"
                            @click="popupOutput(out.param , out.value , $t('{output} '+ out.text, {output: out.name}))"
                        >
                        <span class="dropdown-menu__item-name">
                            {{ $t('{output} '+ out.text , {output: out.name}) }}
                        </span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <button class="button button_view_icon button_theme_base mtb__-4"
                @click.stop="setUnit() && displaySectionInfo('UnitEdit')"
                v-if="accessRight >= getAccessRights.edit"
        >
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit_lg" /></span> </span>
        </button>
        <UnitsList_unit_menu_tracking :unit-id="unitId" />
        <UnitsList_unit_menu_onmap :unit-id="unitId" v-if="false"/>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations,} from "vuex";
    import { getAccessRights } from "@/lib/access-rights";
    import UnitsList_unit_menu_onmap from "./UnitsList_unit_menu_onmap.vue";
    import UnitsList_unit_menu_tracking from "./UnitsList_unit_menu_tracking.vue";

    export default {
        name: 'UnitsList_unit_menu',
        props: [
            'unitId',
        ],
        components: {
            UnitsList_unit_menu_tracking,
            UnitsList_unit_menu_onmap,
        },
        data() {
            return {
                getAccessRights,
            }
        },
        computed: {
            ...mapGetters([
                "unitsByIds"
            ]),
            unit(){
                return this.unitsByIds[this.unitId] || {}
            },
            accessRight(){
                return  this.unit.access_right
            },
            unit_outputs(){
                return this.unit.outputs || []
            },
            output(){
                return this.unit_outputs.reduce((out,o) => {
                    if(o.name > '' && o.param > ''){
                        let inObject = !!out.find(ou => ou.name === o.name && ou.param === o.param)
                        if(!inObject) out.push({...o, value:1, text: 'On'},{...o, value:0, text: 'Off'})
                    }
                    return out
                },[])
            },
        },
        methods: {
            ...mapMutations([
                "setAllUnitsShowOnMap",
                "hideMainList",
            ]),
            ...mapActions([
                "setSectionInfo",
                "setSectionWidget",
                "sendUnitCmd",
                "setUnitActive",
                "setUnitTracking",
                "setMapBoundsByUnits",
                "setPopup"
            ]),
            displaySectionInfo(component){
                this.hideMainList()
                this.setSectionInfo({
                    component: component,
                    props: {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                })
                return true;
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        unitId: this.unitId,
                        time: Date.now()
                    }
                })
                return true;
            },
            setUnit(){
                this.setUnitActive(this.unitId)
                this.setUnitTracking(this.unitId)
                return true;
            },
            popupOutput(output, value, title){
                this.setPopup({
                    title: title,
                    methods: {
                      ok: () => this.setUnitOutput(output, value),
                      //cancel: () => {},
                    }
                })
            },
            setUnitOutput(output, value){
                let cmd = output+(value? " on" : " off")
                this.sendUnitCmd({ id: this.unitId, cmd })
                .then((response) => {
                    //console.log(response)
                    if (response.data && !response.data.error) {
                        this.$toast.success(this.$t("success!"))
                    } else
                    {
                        console.error(response.data);
                        let message = this.$t("error!")
                        if (response.data) {
                            message = response.data.message || response.data.errors || message
                        }
                        this.$toast.error(message)
                    }
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        console.error(error.response)
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    this.$toast.error(this.$t("error!"))
                    //console.error(error.config);
                })
            },
        },
    }
</script>

<style scoped>

</style>